import {
  Page,
  Input,
  Button
} from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import robot_smile from '../../images/robot_smile.png';
import shufu_logo from '../../images/shufu-logo.png';
import getParameterByName from '../../utils/getParameterByName.js';
import {
  setCurrentUserId,
  setOrganizationId,
  setServiceInstanceId, setToken
} from '../../utils/localStorage.js';
import {
  wechaQRList
} from '../../modules/shufu.js';
import './index.css';

// 主体流程：对接his后，使用身份证或其他有效证件查询wechatQr
// 调用startServiceBySceneId开启流程
// 之前山东的是因为路由名称，以及写死的id，徐牙防的对接路由名称中没有orgId，无法通用

// 此路由通用于上述流程
const LoginWithPatientIdentifier = function (props) {
  const { t, i18n } = useTranslation();
  // 证件类型
  const [certificateType, setCertificateType] = useState('patientIdCard');
  // 证件id
  const [certificateVal, setCertificateVal] = useState('');
  const providerId = getParameterByName('providerId');
  const organizationId = getParameterByName('organizationId');

  // 获取患者信息（手动或者扫码）后，创建用户
  const submitHandler = useCallback(async () => {
    
    // 就诊卡号，his系统10位，默认前面补零
    function convertCertificateVal(certificateVal) {
      if (certificateType === 'patientCard') {
        return '00' + certificateVal
      }
      return certificateVal;
    }
    window.$$f7.preloader.show();
    try {
      const params = {
        organizationId,
        [certificateType]: convertCertificateVal(certificateVal),
        tag: 'ziping',
      }
      const data = await wechaQRList(params)
      const length = data.length;
      if (length === 0) {
        window.$$f7.dialog.alert('未查询到您的量表信息', false);
      } else if (length === 1) {
        const wechatQrInfo = data[0];
        const { sceneId, patientProfile } = wechatQrInfo;
        props.f7router.navigate(`/chat-for-his/?providerId=${providerId}`, {
          reloadCurrent : true,
          props: {
            noRestart: false,
            sceneId: sceneId,
            userId: patientProfile.id,
          }
        });
      } else if (length > 1) {
        props.f7router.navigate('/wechatQrList', {
          reloadCurrent : true,
          props: {
            qrList: data
          }
        });
      }
      window.$$f7.preloader.hide();
    } catch (e) {
      window.$$f7.preloader.hide();
      console.log('error', e)
    }
  }, [certificateType, certificateVal, props.f7router, providerId, organizationId])

  const renderPatientIdentifierInput = () => {
    return (
      <div>
        <div style={{ fontSize: '18px', margin: '20px' }}>
          请选择证件类型并输入:
        </div>
        {/* <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}> */}
        <div >
          <div
            style={{
              flex: 1,
              height: 35,
              borderRadius: 10,
              border: `solid 1px #2979ff`,
              backgroundColor: '#deeaff',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
            }}>
            <Input
              type='select'
              value={certificateType}
              onChange={(e) => {
                setCertificateType(e.target.value);
                setCertificateVal('');
              }}
            >
              <option value='patientIdCard'>身份证号</option>
              <option value='patientCard'>就诊卡号/住院号</option>
              {/* <option value='patientMedicalInsuranceCard'>医疗卡</option>
              <option value='patientMedicalInsuranceECode'>医保电子凭证号</option>
              <option value='patientEHealthCode patientEHealthCode'>电子健康卡号</option> */}
            </Input>
            <Input
              type="text"
              placeholder='请输入你的证件号码'
              value={certificateVal}
              onChange={(e) => {
                setCertificateVal(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
    )

  }

  const isInputsValid = () => {
    if (!certificateVal) return false;
    // 山东就诊卡是八位
    if (certificateType === 'patientCard') {
      return certificateVal.length === 8;
    }
    return true;
  }

  const renderSubmitButton = () => {
    return <Button
      raised
      fill
      large
      style={{
        backgroundColor: isInputsValid() ? '#2979ff' : '#c2c2c2',
      }}
      className='welcome-login-button'
      onClick={() => {
        if (!isInputsValid()) return;
        submitHandler()
      }}
    >确定
    </Button>
  }

  // his无法登录，使用shufu-his系统生成的心测号登录
  const renderPatientIdentifierNumLogin = () => {
    return <div className='login-with-patientIdentifier-entry' onClick={jumpPatientIdentifierLogin}><a>使用心测号登录</a></div>
  }

  const jumpPatientIdentifierLogin = () => {
    props.f7router.navigate(`/loginWithPatientIdentifier/?organizationId=${organizationId}&providerId=${providerId}`)
  }


  return (
    <Page noToolbar noNavbar noSwipeback loginScreen className='welcome-container-body'>
      <img className='welcome-shufu-logo' src={shufu_logo}></img>
      <div className='welcome-words'>
        <span className='welcome-words-text'>欢迎！</span>
        <img className='welcome-words-image' src={robot_smile}></img>
      </div>

      {renderPatientIdentifierInput()}
      {renderPatientIdentifierNumLogin()}
      {renderSubmitButton()}
      <div style={{ textAlign: 'center', fontSize: '14px', color: '#8c8c8c' }}>
        Powered by Siuvo.Inc
      </div>
    </Page>
  );
};

LoginWithPatientIdentifier.defaultProps = {

};
export default LoginWithPatientIdentifier;
