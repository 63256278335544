import shufu_logo from '../../images/shufu-logo.png';
import shandong_logo from '../../images/shandong-logo.png';
import changning_logo from '../../images/changning-logo.jpg';
const uiConfigs = {
  shandong: {
    logo: shandong_logo,
    welcomeText: '山东省精神卫生中心欢迎您！',
    notes: '请患方谨慎填写预问诊信息，提高医生就诊效率。'
  },
  changning: {
    logo: changning_logo,
    welcomeText: '欢迎使用AI预问诊系统',
    notes: '请根据患者真实情况填写，数据将保存在院内系统，系统将保证您的个人信息安全。'
  },
  default: {
    logo: shufu_logo,
    welcomeText: '欢迎',
    notes: '请患方谨慎填写量表，分享/多次随意测评导致报告结果错误，由患方承担全部责任）'
  }
}
const pageOrgByEnv = {
  'sdjw': 'shandong',
  'sdjw_uat': 'shandong',
  'sdjw_internal': 'shandong',
  'sdjw_uat_internal': 'shandong',
  'changning': 'changning'
}

export {
  uiConfigs,
  pageOrgByEnv
}